/* Reset HTML/body defaults, apply Apple fonts */
html,
body {
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

/* Reset list styles */
ul,
ol,
li {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
  color: wheat;
}

/* Rogue iframes */
iframe {
  width: 100%;
  height: 100%;
}

p {
  padding: 0;
  margin: 0;
  vertical-align: baseline;
}

/* Variables */
:root {
  --background: #121212;
  --subtle: #1b1b1b;
  --pink: #ff75a0;
  --green: #6fcf97;
  --yellow: #f2c94c;
  --swoosh: #2f80ed;
  --candy: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    #ff75a0;
}

/* Other resets */
* {
  box-sizing: border-box;
}

a.button {
  text-decoration: none;
}

/* Fonts */
@import url("https://fonts.googleapis.com/css?family=Poppins:600,300,400");

/* Styling begins here */
body {
  background: var(--background);
  margin: 0 auto;
}

input {
  border-style: none;
}

input::placeholder {
  color: red;
  font-size: 1.2em;
  font-style: italic;
}

img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

a {
  color: #ff75a0;
  text-decoration: none;
}

.flex {
  display: flex;
  flex-direction: column;
}

.masterContainer {
  padding: 0px 20px;
  height: 100%;
}

/* Nav bar */

.navBar {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-height: 5%;
}

.logo {
  color: var(--yellow);
  cursor: pointer;
  font-family: monospace;
  font-size: 2em;
}

.twitterLink {
  color: var(--pink);
}

/* Join Page */

.appContainer {
  height: 95%;
  padding: 0px 0px 20px 0px;
}

.fieldInput {
  padding: 4px 10px;
  width: 100%;
  height: 52px;
  background: #151515;
  border-radius: 5px;
  font-weight: 300;
  font-size: 20px;
  line-height: 45px;
  color: #6fcf97;
  outline: none;
  font-family: monospace;
}

.joinSectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  min-height: 9em;
  background: linear-gradient(
    to bottom left,
    hsl(51.11, 100%, 15.88%) 0%,
    hsl(48.07, 68.58%, 18.73%) 5.6%,
    hsl(45.59, 57.97%, 19.87%) 10.3%,
    hsl(42.86, 50.44%, 20.74%) 14.3%,
    hsl(39.7, 44.4%, 21.47%) 17.9%,
    hsl(35.95, 39.24%, 22.11%) 21.3%,
    hsl(31.37, 34.65%, 22.7%) 24.7%,
    hsl(25.65, 30.46%, 23.26%) 28.4%,
    hsl(18.31, 26.55%, 23.78%) 32.7%,
    hsl(8.57, 22.86%, 24.28%) 37.9%,
    hsl(355.47, 21.24%, 24.38%) 44.1%,
    hsl(342.58, 23.98%, 23.6%) 51.6%,
    hsl(331.2, 27.37%, 22.73%) 60.7%,
    hsl(321.22, 31.74%, 21.71%) 71.6%,
    hsl(312.6, 37.65%, 20.52%) 84.7%,
    hsl(305.33, 46.39%, 19.02%) 100%
  );
  padding: 1em;
  border-radius: 94% 6% 96% 4% / 48% 49% 51% 52%;
  align-items: center;
}

.joinButton {
  margin-top: 1em;
  align-self: center;
  background: #303030;
  border-radius: 30px;
  font-weight: 600;
  font-size: 2em;
  color: #6fcf97;
  border: 2px solid #00aa66;
  justify-self: center;
  padding: 0.5em 2em;
  overflow: hidden;
  cursor: pointer;
}

.joinButton:hover {
  color: #6fcf97;
  box-shadow: 0 5px 15px rgba(145, 92, 182, 0.4);
}

.joinButton:focus {
  animation: bounce 1s;
  outline: none;
}

.joinButton:after {
  color: #ff7a59;
}

@keyframes bounce {
  0%,
  20%,
  60%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  80% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

.introTitle {
  color: #ff75a0;
  font-weight: lighter;
  font-size: 2rem;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.formLabel {
  color: beige;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: lighter;
  font-size: 1.25rem;
}

.fieldsContainer {
  max-width: 30em;
  gap: 15px;
  margin-bottom: 2em;
}

.statusIndicator {
  color: var(--yellow);
}

/* 
  Chat room page
*/

.channelChatLine {
  display: grid;
  grid-template-columns: 1.2fr 1fr 6fr;
  grid-gap: 10px;
  color: var(--green);
  position: relative;
}

.replyToDiv {
  /* background: sandybrown; */
  top: -0.5rem;
  right: 0;
  width: 2em;
  height: 2rem;
  position: absolute;
  display: none;
}

.channelChatLine:hover .replyToDiv {
  color: var(--yellow);
  /* background: var(--candy); */
  display: block;
}

.channelChatLine:hover {
  color: #00ff00d1;
  background: #2929294f;
  cursor: pointer;
  fill: var(--yellow);
}

.timeSpan {
  opacity: 0.25;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userNameChat {
  color: whitesmoke;
  opacity: 0.7;
  max-height: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.messageChat {
  overflow: hidden;
}

.title {
  font-size: 3em;
}

.darkGrid {
  background: var(--subtle);
}

.grid {
  height: 100%;
  display: grid;
  grid-template-columns: 15% auto auto auto;
  grid-template-rows: 2em 70vh minmax(40px, 100px) auto;
  grid-gap: 0.15rem;
  align-items: stretch;
  grid-template-areas:
    "statusBar statusBar statusBar statusBar"
    "userList channel channel channel"
    "userList channel channel channel"
    "userList chatBox chatBox chatBox";
}

.grid > div,
footer {
  border-radius: 0.15rem;
  padding: 10px;
}

.statusBar {
  border-radius: 0px;
  background: #33333300;
  grid-area: statusBar;
}

.statusBarText {
  font-size: small;
  text-align: end;
  color: #929292;
}

.userList {
  font-family: monospace;
  font-size: 1.25em;
  font-family: monospace;
  grid-area: userList;
}

.channel {
  overflow-y: auto;
  font-size: 1.25em;
  font-family: monospace;
  grid-area: channel;
}

.chatBox {
  color: var(--green);
  overflow: scroll;
  line-height: normal;
  overflow-x: hidden;
  display: inline-block;
  grid-area: chatBox;
}

.chatBox:focus {
  border: 1px solid #6fcf97;
  box-shadow: 0px 0px 0px 4px rgba(75, 77, 237, 0.2);
  border-radius: 8px;
}

.chatBox:hover {
  border: 1px solid #4b4ded;
  box-shadow: 0px 0px 0px 4px rgba(75, 77, 237, 0.2);
  border-radius: 8px;
  outline: none;
}

.chatBox:focus-visible {
  outline: none;
}

.replyToMessageText {
  display: grid;
  gap: 0.5em;
  grid-template-areas:
    "statusBar"
    "userList";
}

.quotedText {
  color: #cf6faa;
  font-style: italic;
  margin-left: 1em;
}

.replyToName {
  color: #f2c94c;
  font-size: 0.8em;
  font-style: italic;
}

.replyToDivider {
  border: 0.01rem solid #e1bb48;
  border-radius: 8px;
  background: #e1bb48;
}

/* Scrollbars! */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 107, 210, 0.623) var(--subtle);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: var(--subtle);
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(255, 107, 210, 0.623);
  border-radius: 30px;
  border: solid 1px;
  fill-opacity: 50%;
}

@media only screen and (max-width: 600px) {
  .grid {
    display: grid;
    font-size: 0.75em;
    grid-template-columns: 15% auto auto auto;
    grid-template-rows: 2em 50vh minmax(40px, 100px) 50px;
    grid-gap: 0.15rem;
    align-items: stretch;
    grid-template-areas:
      "statusBar statusBar statusBar statusBar"
      "channel channel channel channel"
      "channel channel channel channel"
      "userList chatBox chatBox chatBox";
  }

  .userList {
    font-size: smaller;
  }
}
